import { collection, type Timestamp } from "@doitintl/models-types";

import { type AttributionFilter } from "./Dashboard";

@collection("commitmentManagers")
export class CommitmentManagersModel {
  name!: string;

  customerId!: string;

  contracts?: string[];

  contractType!: ContractType;

  currency!: string;

  periods!: Period[];

  periodsSpend?: PeriodSpend[];

  excessRollOverPercentage?: number;

  shortfallRollOverPercentage?: number;

  billingAccounts!: string[];

  serviceFilter?: AttributionFilter;

  skuFilter?: AttributionFilter;

  adjustments?: Record<number, Adjustment[]>;
}

export type ContractType = "google-cloud" | "amazon-web-services";

export type Period = {
  startDate: Timestamp;
  endDate: Timestamp;
  commitmentValue: number;
  marketplaceLimitPercentage: string;
};

export type PeriodSpend = {
  MonthlySpend: MonthlySpend;
  total: number;
  rolloverFromPrevPeriod: number;
  shortfallRollover: number;
};

type MonthlySpend = Record<string, Breakdown>; // <yyyy-mm, Breakdown>

export type Breakdown = {
  services: Record<string, number>;
  credits: Record<string, number>;
  marketplace: Record<string, number>;
  marketplaceTotal: number;
  total: number;
};

export type Adjustment = {
  date: Timestamp;
  amount: number;
  comments?: string;
  invoiceLink?: string;
};
