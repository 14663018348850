import { type FC, useEffect, useState } from "react";

import { type TimestampApiServiceModelDescriptor } from "@doitintl/cmp-models";
import { TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { type FieldInputProps, useFormikContext } from "formik";
import omit from "lodash/omit";
import { DateTime } from "luxon";

import { useFieldCommonProps } from "../useFieldCommonProps";
import { OptionalParamWrapper } from "./wrappers/OptionalParamWrapper";
import { ReferencedFieldWrapper } from "./wrappers/ReferencedField/ReferencedFieldWrapper";

type Transformers<T = string | number> = [
  (value: string | number) => DateTime,
  T extends string ? (value: DateTime) => string | null : T extends number ? (value: DateTime) => number : never,
];

function createTransformersFromFormat(format: string): Transformers {
  switch (true) {
    case format === "X":
      return [
        (value) => (typeof value === "number" ? DateTime.fromSeconds(value) : DateTime.now()),
        (value: DateTime) => value.toUTC().toSeconds(),
      ];
    case format === "x":
      return [
        (value) => (typeof value === "number" ? DateTime.fromMillis(value) : DateTime.now()),
        (value: DateTime) => value.toUTC().toMillis(),
      ];
    default:
      return [
        (value) => DateTime.fromFormat(value as string, format),
        (value: DateTime) => value.toUTC().toFormat(format),
      ];
  }
}

export const TimestampParam: FC<{
  fieldProps: FieldInputProps<string | number | null>;
  label: string;
  inputModel: TimestampApiServiceModelDescriptor;
  onRemove?: () => void;
  disallowReferencedField?: boolean;
  renderAsNotRequired?: boolean;
}> = ({ fieldProps, label, onRemove, inputModel, disallowReferencedField, renderAsNotRequired }) => {
  const formikProps = useFormikContext();
  const format = inputModel.timestampFormat ?? "x";
  const [datetime, setDatetime] = useState<DateTime | null>(null);
  const commonFieldProps = useFieldCommonProps(fieldProps, label, onRemove === undefined && !renderAsNotRequired);
  const [[fromModelValue, toModelValue], setTransformers] = useState<Transformers>(
    createTransformersFromFormat(format)
  );
  useEffect(() => {
    setTransformers(createTransformersFromFormat(format));
  }, [format]);

  useEffect(() => {
    if (fieldProps.value !== null) {
      const newDatetime = fromModelValue(fieldProps.value);
      setDatetime(newDatetime);
    } else {
      setDatetime(null);
    }
  }, [fieldProps.value, fromModelValue]);

  const commonFieldPropsWithoutOnChange = omit(commonFieldProps, "onChange");

  const dateTimePicker = (
    <DateTimePicker
      renderInput={(params) => <TextField {...params} {...commonFieldPropsWithoutOnChange} fullWidth />}
      label={label}
      value={datetime}
      onChange={(datetime) => {
        if (datetime) {
          const modelValue = toModelValue(datetime);
          formikProps.setFieldValue(fieldProps.name, modelValue);
        } else {
          formikProps.setFieldValue(fieldProps.name, null);
        }
      }}
      onClose={() => formikProps.setFieldTouched(fieldProps.name)}
    />
  );

  return (
    <OptionalParamWrapper onRemove={onRemove}>
      {disallowReferencedField ? (
        dateTimePicker
      ) : (
        <ReferencedFieldWrapper commonFieldProps={commonFieldProps} model={inputModel}>
          {dateTimePicker}
        </ReferencedFieldWrapper>
      )}
    </OptionalParamWrapper>
  );
};
