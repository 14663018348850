import { useCallback, useEffect, useMemo, useState } from "react";

import {
  AnalyticsResourceType,
  DashboardModel,
  type DashboardModelSavedReportsModel,
  Roles,
} from "@doitintl/cmp-models";
import {
  type DocumentSnapshotModel,
  Filter,
  getCollection,
  useCollectionData,
  type WithFirebaseModel,
} from "@doitintl/models-firestore";
import intersection from "lodash/intersection";

import { reportText } from "../../../../assets/texts";
import { useAuthContext } from "../../../../Context/AuthContext";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useIsFeatureEntitled, useTier } from "../../../../Context/TierProvider";
import { useUserContext } from "../../../../Context/UserContext";
import { type ReportTemplateWithVersion } from "../../../../Pages/CloudAnalytics/templateLibrary/types";
import { getOrganizationRef } from "../../../../Pages/CloudAnalytics/utilities";
import { type ReportWSnap } from "../../../../types";
import { loadDocumentsByQueriesIfPossible } from "../../../../utils/firebase";

export const reportsTransform = (
  data: WithFirebaseModel<DashboardModelSavedReportsModel>,
  snapshot: DocumentSnapshotModel<DashboardModelSavedReportsModel>,
  currentUserEmail: string
): ReportWSnap => {
  const transformedData = {
    ...data,
    owner: "",
  };
  const filters = {
    schedule: 0,
  };
  for (const collaborator of data.collaborators ?? []) {
    if (collaborator.role === Roles.OWNER) {
      transformedData.owner = collaborator.email;
      break;
    }
  }
  if (data.schedule?.frequency) {
    filters.schedule = data.schedule?.to?.includes(currentUserEmail) ? 2 : 1;
  }
  return {
    data: transformedData,
    snapshot,
    _filters: filters,
  } as unknown as ReportWSnap;
};

export type ReportsValues = [ReportWSnap[], ReportWSnap[], boolean];

const emptyArr = [];

// Do not use this hook directly!
export const useReports = (
  reportTemplatesWithVersions: ReportTemplateWithVersion[] | null,
  managedReportsIds: string[] | null,
  filteredReportTemplateFavorites: string[]
): ReportsValues => {
  const { currentUser, isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const { userOrganization, customer, customerOrPresentationModeCustomer } = useCustomerContext();
  const [managedReports, setManagedReports] = useState<ReportWSnap[] | null>(null);

  const { getFeatureKey } = useTier();
  const allowCustomReports = useIsFeatureEntitled("analytics:reports");
  const allowPresetReports = useIsFeatureEntitled("analytics:reports:presets");
  const allowReportTemplate = useIsFeatureEntitled("analytics:reports:templates", true);
  const allowFlexsaveAWS = useIsFeatureEntitled("savings:flexsaveAWS");
  const allowPdiEks = useIsFeatureEntitled("pdi:eks");

  const isCustomReportsAllowed = isDoitEmployee || allowCustomReports;
  const isPresetReportsAllowed = isDoitEmployee || allowPresetReports;
  const isSuperAdmin = isDoitEmployee || userRoles.cloudAnalyticsAdmin;

  const collectionRef = useMemo(
    () => getCollection(DashboardModel).doc("google-cloud-reports").collection("savedReports"),
    []
  );

  const presetReportsQuery = useMemo(() => {
    if (!isPresetReportsAllowed) return;

    return collectionRef.where("type", "==", AnalyticsResourceType.PRESET).where("customer", "==", null);
  }, [isPresetReportsAllowed, collectionRef]);

  const customPublicReportsQuery = useMemo(() => {
    if (!isCustomReportsAllowed) return;

    let query = collectionRef
      .where("type", "==", AnalyticsResourceType.CUSTOM)
      .where("customer", "==", customer.ref)
      .where("draft", "==", false)
      .where(
        Filter.or(
          Filter.where<DashboardModelSavedReportsModel>()("public", "==", Roles.VIEWER),
          Filter.where<DashboardModelSavedReportsModel>()("public", "==", Roles.EDITOR)
        )
      );

    // Regular users (not admins or Doit employees) can only see reports from their own organization
    if (!isSuperAdmin) {
      const orgRef = getOrganizationRef(false, userOrganization, customer.id);
      const isRootOrg = orgRef.id === "root";

      if (isRootOrg) {
        // If the organization is the root organization, we also include reports that have no organization
        query = query.where(
          Filter.or(
            Filter.where<DashboardModelSavedReportsModel>()("organization", "==", orgRef.nativeRef),
            Filter.where<DashboardModelSavedReportsModel>()("organization", "==", null)
          )
        );
      } else {
        query = query.where("organization", "==", orgRef);
      }
    }

    return query;
  }, [collectionRef, customer.id, customer.ref, isCustomReportsAllowed, isSuperAdmin, userOrganization]);

  // Privately owned/shared reports
  const customSharedReportsQuery = useMemo(() => {
    if (!isCustomReportsAllowed) return;

    let query = collectionRef
      .where("type", "==", AnalyticsResourceType.CUSTOM)
      .where("customer", "==", customer.ref)
      .where("public", "==", null);

    if (!isSuperAdmin) {
      query = query.where("collaborators", "array-contains-any", [
        { email: currentUser.email, role: Roles.OWNER },
        { email: currentUser.email, role: Roles.VIEWER },
        { email: currentUser.email, role: Roles.EDITOR },
      ]);
    }

    return query;
  }, [collectionRef, currentUser.email, customer.ref, isCustomReportsAllowed, isSuperAdmin]);

  const reportsTransformWithEmail = useCallback(
    (
      data: WithFirebaseModel<DashboardModelSavedReportsModel>,
      snapshot: DocumentSnapshotModel<DashboardModelSavedReportsModel>
    ) => reportsTransform(data, snapshot, currentUser.email),
    [currentUser.email]
  );

  const [presetReports, loadingPresetReports] = useCollectionData(presetReportsQuery, {
    transform: reportsTransformWithEmail,
  });

  const [customPublicReports, loadingCustomPublicReports] = useCollectionData(customPublicReportsQuery, {
    transform: reportsTransformWithEmail,
  });

  const [customSharedReports, loadingCustomSharedReports] = useCollectionData(customSharedReportsQuery, {
    transform: reportsTransformWithEmail,
  });

  const fetchManagedReports = useCallback(async () => {
    if (!managedReportsIds || !customer.ref) {
      return;
    }

    const reports = await loadDocumentsByQueriesIfPossible(
      managedReportsIds.map((id) =>
        getCollection(DashboardModel).doc("google-cloud-reports").collection("savedReports").doc(id)
      ),
      {
        useQueryToRetrieveDocs: true,
        useDataField: true,
        snapshotField: "snapshot",
      }
    );

    const reportsNotUndefined = reports
      .filter((r) => !!r)
      .map((r) => ({
        data: {
          ...r.data,
          customer: customer.ref,
          owner: "",
        },
        filters: undefined,
        snapshot: r.snapshot,
      }));

    setManagedReports(reportsNotUndefined);
  }, [customer.ref, managedReportsIds]);

  useEffect(() => {
    if (allowReportTemplate) {
      fetchManagedReports();
    }
  }, [fetchManagedReports, allowReportTemplate]);

  // when feature is not allowed in tier (query = undefined), loading will always be true
  // in this case set loading to false explicitly
  const reportsLoading =
    (isPresetReportsAllowed ? loadingPresetReports : false) ||
    (isCustomReportsAllowed ? loadingCustomPublicReports : false) ||
    (isCustomReportsAllowed ? loadingCustomSharedReports : false);

  const favoriteReportTemplatesActiveReports = useMemo(
    () =>
      filteredReportTemplateFavorites.length
        ? (reportTemplatesWithVersions ?? [])
            .filter((templateWithVersion) => filteredReportTemplateFavorites.includes(templateWithVersion.template.id))
            .map((templateWithVersion) => templateWithVersion.template.activeReport?.id)
            .filter(Boolean)
        : [],
    [filteredReportTemplateFavorites, reportTemplatesWithVersions]
  );

  const reportsList = useMemo(() => {
    if (reportsLoading) {
      return emptyArr;
    }

    const filteredPresetReports = presetReports?.filter((report) => {
      const entitlementsKeys = report.data.entitlements?.map((entitlement) => getFeatureKey(entitlement)) ?? [];
      const isEntitlementReport = report.data.entitlements && report.data.entitlements.length > 0;
      const isFlexsaveReport = allowFlexsaveAWS && entitlementsKeys.includes("savings:flexsaveAWS");
      const isEKSReport = allowPdiEks && entitlementsKeys.includes("pdi:eks");
      return !isEntitlementReport || isFlexsaveReport || isEKSReport;
    });

    return [
      ...(filteredPresetReports ?? []),
      ...(managedReports ?? []),
      ...(customPublicReports ?? []),
      ...(customSharedReports ?? []),
    ];
  }, [
    allowPdiEks,
    customPublicReports,
    customSharedReports,
    managedReports,
    presetReports,
    reportsLoading,
    allowFlexsaveAWS,
    getFeatureKey,
  ]);

  const filteredReports = useMemo(() => {
    if (reportsLoading) {
      return emptyArr;
    }

    return reportsList.filter((report) => {
      let cloudFilter = true;

      if (report.data.type === AnalyticsResourceType.PRESET && report.data.cloud?.length) {
        cloudFilter = intersection(customerOrPresentationModeCustomer?.assets, report.data.cloud).length > 0;
      }

      if (report.data.type === AnalyticsResourceType.MANAGED) {
        cloudFilter = favoriteReportTemplatesActiveReports.includes(report.snapshot.id);
      }

      return (
        cloudFilter && !report.data.draft && !report.data.hidden && report.data.name !== reportText.EXPORTED_REPORT
      );
    });
  }, [reportsLoading, reportsList, customerOrPresentationModeCustomer?.assets, favoriteReportTemplatesActiveReports]);

  return [reportsList, filteredReports, reportsLoading];
};
