import React, { useEffect, useRef } from "react";

import {
  type Adjustment,
  type CommitmentManagersModel,
  type ContractType,
  type CurrencyCode,
  type Period,
  type PeriodSpend,
} from "@doitintl/cmp-models";
import { type ModelId } from "@doitintl/models-firestore";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { Box, Button, Card, Divider, LinearProgress, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import styled from "styled-components";

import AWSLogo from "../../assets/aws-logo.svg";
import AWSLogoDark from "../../assets/aws-logo-dark-mode.svg";
import GCPLogo from "../../assets/gcp-logo.svg";
import { useDarkThemeCheck } from "../../Components/hooks/useDarkThemeCheck";
import { formatValueWithCurrency } from "../../Components/hooks/useFormatter";
import { getCurrencyByCode } from "../../utils/common";
import { StatusChip } from "../RampPlans/components/StatusChips";
import { calculateRemaining, calucluateTotalPeriodSpend, getCurrentPeriodIndex, getPeriodStatus } from "./utils";

interface StyledLinearProgressProps {
  barcolor: string;
  rootcolor: string;
}

const StyledLinearProgress = styled(
  (props: StyledLinearProgressProps & React.ComponentProps<typeof LinearProgress>) => <LinearProgress {...props} />
)`
  .MuiLinearProgress-barColorPrimary {
    background-color: ${(props) => props.barcolor};
  }
  .MuiLinearProgress-colorPrimary {
    background-color: ${(props) => props.rootcolor};
  }
`;

interface CommitmentCardProps {
  commitment: ModelId<CommitmentManagersModel>;
  onCardClick: (commitmentId: string) => void;
}

interface CommitmentPeriodRowProps {
  period: Period;
  index: number;
  isCurrent: boolean;
  currency: CurrencyCode;
  isDarkMode: boolean;
  periodSpend?: PeriodSpend;
  adjustments?: Adjustment[];
}

const CommitmentPeriodRow = React.memo(
  React.forwardRef<HTMLDivElement, CommitmentPeriodRowProps>(
    ({ period, index, isCurrent, currency, isDarkMode, periodSpend, adjustments }, ref) => {
      const periodStatus = getPeriodStatus(period.startDate.toDate(), period.endDate.toDate());
      const startDateStr = DateTime.fromJSDate(period.startDate.toDate()).toFormat("d MMM yyyy");
      const endDateStr = DateTime.fromJSDate(period.endDate.toDate()).toFormat("d MMM yyyy");
      const totalSpend = calucluateTotalPeriodSpend(periodSpend, adjustments);
      const remaining = calculateRemaining(periodSpend, period, adjustments);
      const commitmentAmount = period.commitmentValue;
      const progressValue = totalSpend / commitmentAmount;
      const valueLabel = isNaN(progressValue) ? "0%" : `${Math.round(progressValue * 100)}%`;

      const rootColor = isDarkMode ? "#424242" : "#eeeeee";

      let barColor;
      if (isCurrent) {
        barColor = isDarkMode ? "#2196f3" : "#1976d2";
      } else {
        barColor = isDarkMode ? "#9e9e9e" : "#757575";
      }

      return (
        <Box ref={ref} sx={{ mb: 2 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 0.5 }}>
            <Box>
              <Typography variant="subtitle2">
                Period {index + 1} {periodStatus === "active" && <StatusChip status={periodStatus} />}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {startDateStr} - {endDateStr}
              </Typography>
            </Box>
          </Stack>
          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <Box sx={{ width: "100%", mr: 1 }}>
              <StyledLinearProgress
                style={{ height: "16px", borderRadius: "8px" }}
                variant="buffer"
                valueBuffer={100}
                value={Math.min(progressValue * 100, 100)}
                barcolor={barColor}
                rootcolor={rootColor}
              />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="textSecondary">
                {valueLabel}
              </Typography>
            </Box>
          </Box>
          {periodStatus !== "notStarted" && (
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
              {period.commitmentValue && (
                <Typography variant="body2">
                  {`${formatValueWithCurrency(totalSpend, 2, currency, true)} ${totalSpend > commitmentAmount ? "over" : "out of"} ${formatValueWithCurrency(commitmentAmount, 2, currency, true)}`}
                </Typography>
              )}
              {periodStatus === "inactive" &&
                (remaining > 0 ? (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ fontWeight: "bold", display: "flex", alignItems: "center" }}
                  >
                    <TrendingDownIcon fontSize="small" sx={{ mr: 0.5 }} />
                    {`${formatValueWithCurrency(Math.abs(remaining), 2, currency, true)} shortfall`}
                  </Typography>
                ) : remaining < 0 ? (
                  <Typography
                    variant="body2"
                    color="success.main"
                    sx={{ fontWeight: "bold", display: "flex", alignItems: "center" }}
                  >
                    <TrendingUpIcon fontSize="small" sx={{ mr: 0.5 }} />
                    {`${formatValueWithCurrency(Math.abs(remaining), 2, currency, true)} excess`}
                  </Typography>
                ) : (
                  <Box />
                ))}
            </Stack>
          )}
        </Box>
      );
    }
  )
);

CommitmentPeriodRow.displayName = "CommitmentPeriodRow";

export const CommitmentCard: React.FC<CommitmentCardProps> = React.memo(({ commitment, onCardClick }) => {
  const isDarkMode = useDarkThemeCheck();
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const currentPeriodRef = useRef<HTMLDivElement | null>(null);

  const getCommitmentData = (commitment: ModelId<CommitmentManagersModel>) => {
    if (!commitment.periods || commitment.periods.length === 0) {
      return {
        hasPeriods: false,
        firstPeriod: undefined,
        lastPeriod: undefined,
        startDate: undefined,
        endDate: undefined,
        endDateDT: undefined,
      };
    }

    const firstPeriod = commitment.periods[0];
    const lastPeriod = commitment.periods[commitment.periods.length - 1];
    const startDate = firstPeriod.startDate.toDate();
    const endDate = lastPeriod.endDate.toDate();
    const endDateDT = DateTime.fromJSDate(lastPeriod.endDate.toDate()).toUTC();

    return {
      hasPeriods: true,
      firstPeriod,
      lastPeriod,
      startDate,
      endDate,
      endDateDT,
    };
  };

  const getProviderLogo = (contractType: ContractType): string => {
    if (contractType === "google-cloud") {
      return GCPLogo as string;
    }
    return (isDarkMode ? AWSLogoDark : AWSLogo) as string;
  };

  const getTotalCommitmentValue = (commitment: ModelId<CommitmentManagersModel>) => {
    const { hasPeriods } = getCommitmentData(commitment);

    if (!hasPeriods) {
      return 0;
    }

    return commitment.periods.reduce((total, period) => total + (period.commitmentValue || 0), 0);
  };

  const getCommitmentStatus = (commitment: ModelId<CommitmentManagersModel>) => {
    const { hasPeriods, startDate, endDate } = getCommitmentData(commitment);

    if (!hasPeriods || !startDate || !endDate) {
      return "notStarted";
    }

    return getPeriodStatus(startDate, endDate);
  };

  const getTotalDuration = (commitment: ModelId<CommitmentManagersModel>) => {
    const { hasPeriods, startDate, endDate } = getCommitmentData(commitment);

    if (!hasPeriods || !startDate || !endDate) {
      return "N/A";
    }

    const startDateDT = DateTime.fromJSDate(startDate).toUTC();
    const endDateDT = DateTime.fromJSDate(endDate).toUTC();

    const diff = endDateDT.diff(startDateDT, ["years", "months"]);
    const years = Math.floor(diff.years);
    const months = Math.floor(diff.months);

    if (years > 0) {
      return `${years} year${years > 1 ? "s" : ""}${months > 0 ? ` ${months} month${months > 1 ? "s" : ""}` : ""}`;
    } else if (months > 0) {
      return `${months} month${months > 1 ? "s" : ""}`;
    } else {
      return "Less than a month";
    }
  };

  const status = getCommitmentStatus(commitment);
  const totalCommitment = getTotalCommitmentValue(commitment);
  const totalDuration = getTotalDuration(commitment);
  const logo = getProviderLogo(commitment.contractType);
  const currency = getCurrencyByCode(commitment.currency);
  const currentPeriodIndex = getCurrentPeriodIndex(commitment.periods);

  const keyInfoLabel = {
    variant: "body2" as const,
    color: "text.secondary",
    mb: 0.5,
  };

  const keyInfoValue = {
    variant: "subtitle1" as const,
    fontWeight: "500",
  };

  useEffect(() => {
    if (scrollContainerRef.current && currentPeriodIndex >= 0) {
      const container = scrollContainerRef.current;
      const targetElement = currentPeriodRef.current;
      if (!targetElement || !container) return;

      const targetPosition = targetElement.offsetTop;
      const startPosition = container.scrollTop;
      const distance = targetPosition - startPosition;

      // Smooth scroll animation
      const duration = 300; // ms
      let start: number | null = null;

      const animateScroll = (timestamp: number) => {
        if (!start) start = timestamp;
        const elapsed = timestamp - start;
        const progress = Math.min(elapsed / duration, 1);

        const easeInOutQuad = (t: number) => (t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2);

        container.scrollTop = startPosition + distance * easeInOutQuad(progress);

        if (progress < 1) {
          requestAnimationFrame(animateScroll);
        }
      };

      requestAnimationFrame(animateScroll);
    }
  }, [currentPeriodIndex]);

  return (
    <Card
      key={commitment.id}
      sx={{
        my: 2,
        p: 3,
      }}
    >
      <Stack direction="row" alignItems="center" spacing={1.5} sx={{ mb: 3 }}>
        <Box
          component="img"
          src={logo}
          alt={commitment.contractType === "google-cloud" ? "Google Cloud" : "AWS"}
          sx={{ height: "32px", width: "32px" }}
        />
        <Typography variant="h4" noWrap sx={{ fontWeight: "bold", flex: 1 }}>
          {commitment.name}
        </Typography>
      </Stack>

      <Divider sx={{ my: 3 }} />

      <Stack direction="row" spacing={3} justifyContent="space-between" alignItems="center" sx={{ flexWrap: "wrap" }}>
        <Stack direction="row" spacing={3} sx={{ flexWrap: "wrap", flex: 1 }}>
          <Stack direction="column" spacing={0.5}>
            <Typography {...keyInfoLabel}>Status</Typography>
            <Stack sx={{ maxWidth: "100px" }}>
              <StatusChip status={status} />
            </Stack>
          </Stack>
          <Stack direction="column" spacing={0.5}>
            <Typography {...keyInfoLabel}>Total commitment value</Typography>
            <Typography {...keyInfoValue}>{formatValueWithCurrency(totalCommitment, 2, currency, true)}</Typography>
          </Stack>
          <Stack direction="column" spacing={0.5}>
            <Typography {...keyInfoLabel}>Duration</Typography>
            <Typography {...keyInfoValue}>{totalDuration}</Typography>
          </Stack>
          <Stack direction="column" spacing={0.5}>
            <Typography {...keyInfoLabel}>Start & End dates</Typography>
            <Typography {...keyInfoValue}>
              {commitment.periods && commitment.periods.length > 0 && (
                <>
                  {DateTime.fromJSDate(commitment.periods[0].startDate.toDate()).toFormat("d MMM yyyy")} to{" "}
                  {DateTime.fromJSDate(commitment.periods[commitment.periods.length - 1].endDate.toDate()).toFormat(
                    "d MMM yyyy"
                  )}
                </>
              )}
            </Typography>
          </Stack>
        </Stack>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            onCardClick(commitment.id);
          }}
        >
          View details
        </Button>
      </Stack>

      <Divider sx={{ my: 3 }} />

      {commitment.periods && commitment.periods.length > 0 && (
        <>
          <Box
            ref={scrollContainerRef}
            sx={{
              maxHeight: "250px",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: isDarkMode ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)",
                borderRadius: "4px",
              },
            }}
          >
            {commitment.periods.map((period, index) => {
              const periodSpend = commitment.periodsSpend?.[index];
              const isCurrent = index === currentPeriodIndex;
              return (
                <React.Fragment key={index}>
                  {index > 0 && <Divider sx={{ my: 2 }} />}
                  <CommitmentPeriodRow
                    period={period}
                    index={index}
                    isCurrent={isCurrent}
                    currency={currency}
                    isDarkMode={isDarkMode}
                    periodSpend={periodSpend}
                    adjustments={commitment.adjustments?.[index]}
                    ref={isCurrent ? currentPeriodRef : null}
                  />
                </React.Fragment>
              );
            })}
          </Box>
          {commitment.periods.length > 2 && (
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1, fontStyle: "italic" }}>
              Showing all periods. Scroll to view more.
            </Typography>
          )}
        </>
      )}
    </Card>
  );
});

CommitmentCard.displayName = "CommitmentCard";
