import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import { Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";

import useAllocationDndStore from "../../hooks/useAllocationDnd";

const ActionButtons = ({
  canDuplicate,
  canRemove,
  onDuplicateRule,
  onRemoveRule,
}: {
  canDuplicate: boolean;
  canRemove: boolean;
  onDuplicateRule: () => void;
  onRemoveRule: () => void;
}) => {
  const { isAllocationOwner } = useAllocationDndStore();
  const canDuplicateOrNotOwner = !canDuplicate || !isAllocationOwner;
  const canRemoveOrNotOwner = !canRemove || !isAllocationOwner;
  return (
    <Stack direction="row">
      <IconButton onClick={onDuplicateRule} aria-label="copy" disabled={canDuplicateOrNotOwner}>
        <ContentCopyIcon color={canDuplicateOrNotOwner ? "disabled" : "action"} />
      </IconButton>
      <IconButton onClick={onRemoveRule} aria-label="delete" disabled={canRemoveOrNotOwner}>
        <DeleteIcon color={canRemoveOrNotOwner ? "disabled" : "action"} />
      </IconButton>
    </Stack>
  );
};

export default ActionButtons;
