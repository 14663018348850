import { type FC, memo } from "react";

import { Box, Chip, useTheme } from "@mui/material";
import { BaseEdge, type Edge, EdgeLabelRenderer, type EdgeProps, getSmoothStepPath } from "@xyflow/react";

import { type EdgeData } from "../../types";
import AddNodePopover from "./AddNodePopover";

const InternalConditionEdge: FC<EdgeProps<Edge<EdgeData>>> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  target,
}) => {
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const {
    palette: { general, divider },
  } = useTheme();

  return (
    <>
      <BaseEdge interactionWidth={0} id={id} path={edgePath} style={{ stroke: divider, pointerEvents: "none" }} />
      <EdgeLabelRenderer>
        <Chip
          label={data?.label}
          sx={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`,
            backgroundColor: general.backgroundDark,
            color: "text.primary",
            borderColor: divider,
          }}
          variant="outlined"
        />
        {target.includes("ghost") && (
          <Box
            sx={{
              position: "absolute",
              transform: `translate(-50%, -40%) translate(${targetX}px,${targetY}px)`,
            }}
          >
            <AddNodePopover data={data} target={target} />
          </Box>
        )}
      </EdgeLabelRenderer>
    </>
  );
};

const ConditionEdge = memo(InternalConditionEdge);
export default ConditionEdge;
