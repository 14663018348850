import "@xyflow/react/dist/style.css";

import { useCallback, useEffect } from "react";

import { Prompt, useHistory, useParams } from "react-router";
import { CloudFlowNodeType, type CloudFlowProvider as CloudFlowProviderType } from "@doitintl/cmp-models";
import { Alert, Box, Stack, useTheme } from "@mui/material";
import {
  Controls,
  type EdgeTypes,
  MiniMap,
  type Node,
  type NodeTypes,
  ReactFlow,
  ReactFlowProvider,
} from "@xyflow/react";

import { CircularProgressLoader } from "../../../Components/Loader";
import { useErrorSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { consoleErrorWithSentry } from "../../../utils";
import { useFullScreen } from "../../../utils/dialog";
import CloudflowSidePanel from "../CloudflowSidePanel/SidePanelContent";
import { ChangeTriggerDialog } from "../Dialog/ChangeTriggerDialog";
import { DeleteIfNodeDialog } from "../Dialog/DeleteIfNodeDialog";
import { ManageIfNodeDialog } from "../Dialog/ManageIfNodeDialog";
import { RemoveReferencedNodeDialog } from "../Dialog/RemoveReferencedNode";
import { useGetCloudflow } from "../hooks";
import { EDGE_TYPE, type RFNode } from "../types";
import { ActionSearchDialog } from "./ActionSearchDialog/ActionSearchDialog";
import { BlueprintDialog } from "./BlueprintDialog/BlueprintDialog";
import { ModalProvider, useModalManager } from "./Common/CloudflowModalsProvider";
import { CloudflowOperationProvider } from "./Common/CloudflowOperationsProvider";
import { CloudFlowProvider } from "./Common/CloudFlowProvider";
import { useCloudflowState } from "./Common/hooks/useCloudflowState";
import { NodeEdgeManagerProvider, useNodeEdgeManager } from "./Common/NodeEdgeManagerProvider";
import ConditionEdge from "./Edge/ConditionEdge";
import CustomEdge from "./Edge/CustomEdge";
import GhostEdge from "./Edge/GhostEdge";
import { ActionNode } from "./Node/ActionNode";
import { ConditionNode } from "./Node/ConditionNode";
import { FilterNode } from "./Node/FilterNode";
import { GhostNode } from "./Node/GhostNode";
import { StartStepConfigurator } from "./Node/StartStepConfigurator";
import { TransformNode } from "./Node/TransformNode";
import { TriggerNode } from "./Node/TriggerNode";
import Topbar from "./Topbar/Topbar";

const edgeTypes: EdgeTypes = {
  [EDGE_TYPE.CUSTOM]: CustomEdge,
  [EDGE_TYPE.GHOST]: GhostEdge,
  [EDGE_TYPE.CONDITION]: ConditionEdge,
};

const nodeTypes: NodeTypes = {
  [CloudFlowNodeType.START_STEP]: StartStepConfigurator,
  [CloudFlowNodeType.GHOST]: GhostNode,
  [CloudFlowNodeType.TRIGGER]: TriggerNode,
  [CloudFlowNodeType.MANUAL_TRIGGER]: TriggerNode,
  [CloudFlowNodeType.ACTION]: ActionNode,
  [CloudFlowNodeType.CONDITION]: ConditionNode,
  [CloudFlowNodeType.TRANSFORMATION]: TransformNode,
  [CloudFlowNodeType.FILTER]: FilterNode,
};

export const CloudflowEditorContent = () => {
  const theme = useTheme();

  const { isMobile } = useFullScreen("sm");

  const {
    nodes,
    edges,
    onConnect,
    onEdgeClick,
    manageIfActionsId,
    setManageIfActionsId,
    onSaveManageIfActionsDialog,
    onConfirmDeleteIfNode,
    deleteIfNodeId,
    setDeleteIfNodeId,
    handleNodeOperation,
    interactionEnabled,
    onConfirmChangeTrigger,
    operationType,
    onNodesChange,
    onEdgesChange,
    activeNode,
    handleAddBlueprint,
    nodesWithReferencedNode,
    handleDeleteReferencedNode,
    selectedProvider,
  } = useNodeEdgeManager();

  const { isModalVisible, focusedNodeId, closeModal } = useModalManager();
  const hasUnsavedChanges = nodes?.some((n) => n.data.touched);
  const { isPublished, isBlueprint } = useCloudflowState();

  useEffect(() => {
    const beforeUnload = (e: BeforeUnloadEvent) => {
      if (!hasUnsavedChanges) return;
      e.preventDefault();

      // recommended by MDN for compatability with older browsers
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#examples
      // eslint-disable-next-line deprecation/deprecation
      e.returnValue = true;
    };

    window.addEventListener("beforeunload", beforeUnload);
    return () => {
      window.removeEventListener("beforeunload", beforeUnload);
    };
  }, [hasUnsavedChanges]);

  const getActionProvider = useCallback(() => {
    if (selectedProvider) {
      return selectedProvider;
    }

    const focusedNode = nodes.find((node) => node.id === focusedNodeId) as Node<RFNode<CloudFlowNodeType.ACTION>>;
    return focusedNode?.data?.nodeData?.parameters?.provider as CloudFlowProviderType;
  }, [selectedProvider, focusedNodeId, nodes]);

  return (
    <>
      <style>
        {`
      #main-layout{
        >[role="alert"],>header {
          display: none;
        }
      }
      `}
      </style>
      <Prompt
        when={hasUnsavedChanges && !isBlueprint && !isPublished}
        message={"Changes that you made may not be saved."}
      />
      <Stack
        sx={{
          backgroundColor: theme.palette.general.backgroundDark,
          height: "100%",
        }}
      >
        <Topbar />
        <Box flex={1}>
          {isMobile && (
            <Alert severity="info" sx={{ position: "relative", top: 64, zIndex: 1, left: 16, mr: 4 }}>
              To get the best out of CloudFlow please use a device with a larger screen resolution such as a desktop or
              laptop.
            </Alert>
          )}
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            proOptions={{ hideAttribution: true }}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            edgeTypes={edgeTypes}
            onEdgeClick={onEdgeClick}
            fitView
            fitViewOptions={{ maxZoom: 1 }}
            nodesDraggable={false}
            onlyRenderVisibleElements={true}
            style={{ transition: "margin 0.3s", marginRight: activeNode ? "450px" : "0" }}
            deleteKeyCode={null}
            zoomOnScroll={interactionEnabled}
            panOnDrag={interactionEnabled}
            edgesFocusable={false}
          >
            <MiniMap pannable zoomable position="bottom-left" />
          </ReactFlow>

          <Controls orientation="horizontal" showInteractive={false} style={{ direction: "rtl" }} />
        </Box>
      </Stack>
      {!!deleteIfNodeId && (
        <DeleteIfNodeDialog
          open={!!deleteIfNodeId}
          onConfirm={onConfirmDeleteIfNode}
          onCancel={() => {
            setDeleteIfNodeId("");
          }}
        />
      )}
      {!!manageIfActionsId && (
        <ManageIfNodeDialog
          open={!!manageIfActionsId}
          onCancel={() => {
            setManageIfActionsId("");
          }}
          onSave={onSaveManageIfActionsDialog}
        />
      )}
      {isModalVisible("action") && !!focusedNodeId && getActionProvider() && (
        <ActionSearchDialog
          onOperationSelect={({ operationName, provider, service, version }) => {
            handleNodeOperation(
              focusedNodeId,
              {
                objectID: operationName,
                operationName,
                provider: provider as unknown as CloudFlowProviderType,
                serviceName: service,
                serviceNameShort: service,
                versionId: version,
              },
              operationType
            );
          }}
          selectedProvider={getActionProvider()}
        />
      )}
      {isModalVisible("blueprint") && !!focusedNodeId && (
        <BlueprintDialog handleAddBlueprint={handleAddBlueprint} focusedNodeId={focusedNodeId} />
      )}

      <RemoveReferencedNodeDialog
        isOpen={isModalVisible("removeReferencedNode")}
        onCancel={() => {
          closeModal("removeReferencedNode");
        }}
        onConfirm={handleDeleteReferencedNode}
        nodesWithReferencedNode={nodesWithReferencedNode}
      />

      {activeNode && isModalVisible("trigger") && (
        <ChangeTriggerDialog
          nodeType={activeNode.type as CloudFlowNodeType}
          open={!!activeNode}
          onConfirm={onConfirmChangeTrigger}
        />
      )}
    </>
  );
};

export const CloudFlowEditor = () => {
  const { flowId, customerId } = useParams<{ customerId: string; flowId: string }>();
  const history = useHistory();
  const errorSnackbar = useErrorSnackbar();

  const {
    data: flow,
    isLoading,
    isError,
    error,
  } = useGetCloudflow({
    customerId,
    flowId,
  });

  if (isLoading) {
    return <CircularProgressLoader />;
  }

  if (isError) {
    consoleErrorWithSentry(error || new Error(`Flow not found: ${flowId}`));
    errorSnackbar("Failed to load CloudFlow");
    history.push(`/customers/${customerId}/cloudflow`);
    return null;
  }

  return (
    <Box position="absolute" sx={{ inset: 0 }}>
      <ReactFlowProvider>
        <ModalProvider>
          <CloudflowOperationProvider>
            <CloudFlowProvider flow={flow}>
              <NodeEdgeManagerProvider>
                <CloudflowEditorContent />
              </NodeEdgeManagerProvider>
              <CloudflowSidePanel />
            </CloudFlowProvider>
          </CloudflowOperationProvider>
        </ModalProvider>
      </ReactFlowProvider>
    </Box>
  );
};
