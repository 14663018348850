import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";

import { Button, ClickAwayListener } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { allocationTxt } from "../../../../../assets/texts/CloudAnalytics/allocation";
import { attributionTxt } from "../../../../../assets/texts/CloudAnalytics/attribution";
import HtmlTooltip from "../../../../../Components/Tooltip/HtmlTooltip";
import { useFullScreen } from "../../../../../utils/dialog";
import { getLetterForIndex } from "../../../../../utils/string";
import { type FormActions, type FormState } from "../../hooks/useAllocationFormState";
import { updateFormula } from "../../utils";

const LOGIC_PART_WIDTH = 540;

interface LogicalFormulaInputProps {
  formStore: () => FormState & FormActions;
  guidedExperienceMode?: boolean;
  disabled?: boolean;
}

const LogicalFormulaInput = ({ formStore, guidedExperienceMode = false, disabled }: LogicalFormulaInputProps) => {
  const { values, errors, setFormula } = formStore();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const { isMobile } = useFullScreen();
  const filtersLength = useRef(values.filterFields.length);
  const inputRef = useRef<HTMLInputElement>(null);
  const position = useRef<{ beforeStart: number; beforeEnd: number }>({ beforeStart: 0, beforeEnd: 0 });

  useLayoutEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.setSelectionRange(position.current.beforeStart, position.current.beforeEnd);
  }, [values.formula]);

  const formula = useMemo(
    () => values.filterFields.map((_, idx) => getLetterForIndex(idx)).join(" AND "),
    [values.filterFields]
  );

  const handleTooltipOpen = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsTooltipOpen(true);
  }, []);

  const handleTooltipClose = useCallback(() => {
    setIsTooltipOpen(false);
  }, []);

  const handleFormulaChange = useCallback(() => {
    // Only update formula if number of filters has changed
    if (filtersLength.current === values.filterFields.length) return;
    // Update the reference to current filter length
    filtersLength.current = values.filterFields.length;

    const variables = values.filterFields.map((_, idx) => getLetterForIndex(idx));

    if (!values.formula) {
      setFormula(formula);
    }

    // If there's no existing formula or filters changed, generate a new one
    setFormula(updateFormula(values.formula, variables));
  }, [formula, setFormula, values.filterFields, values.formula]);

  useEffect(() => {
    handleFormulaChange();
  }, [handleFormulaChange]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const beforeStart = e.target.selectionStart ?? 0;
      const beforeEnd = e.target.selectionEnd ?? 0;

      position.current = {
        beforeStart,
        beforeEnd,
      };
      setFormula(e.target.value.toUpperCase());
    },
    [setFormula]
  );

  return (
    <Grid container gap={1} sx={{ flexDirection: "column" }}>
      <Grid sx={{ width: { md: LOGIC_PART_WIDTH } }}>
        <TextField
          fullWidth
          required
          inputRef={inputRef}
          value={values.formula}
          variant="outlined"
          label={allocationTxt.DEFINE_LOGIC}
          size="small"
          sx={{ mt: 1 }}
          onChange={handleChange}
          error={!!errors.formula}
          disabled={disabled || isMobile || values.filterFields.length < 2}
          helperText={<>{errors.formula}</>}
          slotProps={{
            htmlInput: {
              "data-cy": "formulaTextField",
              sx: { textTransform: "uppercase", backgroundColor: "background.paper" },
              autoComplete: "off",
            },
          }}
        />
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <span>
            <HtmlTooltip
              placement="bottom"
              onClose={handleTooltipClose}
              open={isTooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={
                <Box>
                  <Typography variant="subtitle1" fontWeight={500} mb={1}>
                    {attributionTxt.LOGIC_ALERT_TITLE}
                  </Typography>
                  <Stack gap={2}>
                    {attributionTxt.LOGIC_ALERT_RULES.map((val) => (
                      <Typography key={val}>{val}</Typography>
                    ))}
                  </Stack>
                </Box>
              }
            >
              <Button variant="text" onClick={handleTooltipOpen}>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  sx={{ borderBottom: "1px dotted", cursor: "pointer" }}
                >
                  {allocationTxt.LEARN_HOW_TO_DEFINE_LOGIC_TITLE}
                </Typography>
              </Button>
            </HtmlTooltip>
          </span>
        </ClickAwayListener>
      </Grid>
      {guidedExperienceMode && (
        <Typography
          variant="caption"
          color="textSecondary"
          sx={{
            pl: 1,
          }}
        >
          {attributionTxt.OPERATORS_BETWEEN_RULES}
          <Link
            color="inherit"
            href="https://help.doit.com/docs/cloud-analytics/attributing-cloud-spend/create-attribution#create-an-attribution-from-scratch"
            target="_blank"
            variant="inherit"
            sx={{
              pl: 0.5,
            }}
          >
            {attributionTxt.LEARN_MORE}
          </Link>
        </Typography>
      )}
    </Grid>
  );
};

export default LogicalFormulaInput;
