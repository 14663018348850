import * as yup from "yup";

import { getLetterForIndex } from "../../../utils/string";

const formSchema = yup.object({
  name: yup.string().trim().min(1).required("Name is required"),
  filterFields: yup.array().min(1, "At least one filter field is required"),
  formula: yup.string().test({
    name: "is-valid-formula",
    test(value, context) {
      if (!value) {
        // Empty string check
        return this.createError({ message: "Empty string" });
      }

      const variables = context.parent.filterFields.map((_, idx) => getLetterForIndex(idx));

      // Tokenize the expression
      const tokens = tokenize(value);

      // Check for syntax errors
      const syntaxError = checkSyntax(tokens);
      if (syntaxError) {
        return this.createError({ message: syntaxError });
      }

      // Check if all variables used in the formula are from the allowed variables array
      const { invalidVariables, unusedVariables } = checkVariables(tokens, variables);

      if (invalidVariables.length > 0) {
        return this.createError({
          message: `Invalid variables: ${invalidVariables.join(", ")}. Allowed variables are: ${variables.join(", ")}`,
        });
      }

      // Check if all allowed variables are used in the formula
      if (unusedVariables.length > 0) {
        return this.createError({
          message: `Not all variables are used. Unused variables: ${unusedVariables.join(", ")}`,
        });
      }

      // If no syntax errors and all variables are allowed, expression is valid
      return true;
    },
  }),
});

/**
 * Define token type
 */
interface Token {
  type: "AND" | "OR" | "NOT" | "LPAREN" | "RPAREN" | "VAR" | "UNKNOWN";
  value: string;
}

/**
 * Tokenize the boolean expression into an array of tokens
 */
function tokenize(expression: string): Token[] {
  const tokens: Token[] = [];
  let i = 0;

  // Remove all whitespace except inside variable names
  expression = expression.trim();

  while (i < expression.length) {
    if (expression[i] === " ") {
      // Skip spaces (they'll be considered in the syntax checker)
      i++;
      continue;
    } else if (expression.substring(i, i + 3) === "AND") {
      tokens.push({ type: "AND", value: "AND" });
      i += 3;
    } else if (expression.substring(i, i + 2) === "OR") {
      tokens.push({ type: "OR", value: "OR" });
      i += 2;
    } else if (expression.substring(i, i + 3) === "NOT") {
      tokens.push({ type: "NOT", value: "NOT" });
      i += 3;
    } else if (expression[i] === "(") {
      tokens.push({ type: "LPAREN", value: "(" });
      i++;
    } else if (expression[i] === ")") {
      tokens.push({ type: "RPAREN", value: ")" });
      i++;
    } else if (/[A-Z]/.test(expression[i])) {
      // Variable name (only uppercase letters are allowed as variables)
      let varName = "";
      while (i < expression.length && /[A-Z]/.test(expression[i])) {
        varName += expression[i];
        i++;
      }
      tokens.push({ type: "VAR", value: varName });
    } else {
      // Unrecognized character - special character handling
      tokens.push({ type: "UNKNOWN", value: expression[i] });
      i++;
    }
  }

  return tokens;
}

/**
 * Check syntax of the tokenized expression
 */
function checkSyntax(tokens) {
  if (tokens.length === 0) {
    return "Empty expression";
  }

  // Check for invalid characters
  const invalidTokens = tokens.filter((token) => token.type === "UNKNOWN");
  if (invalidTokens.length > 0) {
    const invalidChars = invalidTokens.map((token) => `'${token.value}'`).join(", ");
    return `Invalid characters found: ${invalidChars}. Only variables, operators (AND, OR, NOT) and parentheses are allowed.`;
  }

  // Check for empty parentheses
  for (let i = 0; i < tokens.length - 1; i++) {
    if (tokens[i].type === "LPAREN" && tokens[i + 1].type === "RPAREN") {
      return "Empty parentheses";
    }
  }

  // Check parentheses matching
  let parenCount = 0;
  for (const token of tokens) {
    if (token.type === "LPAREN") parenCount++;
    if (token.type === "RPAREN") parenCount--;
    if (parenCount < 0) return "Unmatched close parenthesis";
  }
  if (parenCount > 0) return "Unmatched open parenthesis";

  // Check for proper structure
  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    const nextToken = i < tokens.length - 1 ? tokens[i + 1] : null;

    // Check for missing operand after NOT
    if (
      token.type === "NOT" &&
      (!nextToken || (nextToken.type !== "VAR" && nextToken.type !== "LPAREN" && nextToken.type !== "NOT"))
    ) {
      return "NOT requires operand within parentheses";
    }

    // Check for missing operand after AND/OR
    if (
      (token.type === "AND" || token.type === "OR") &&
      (!nextToken || (nextToken.type !== "VAR" && nextToken.type !== "LPAREN" && nextToken.type !== "NOT"))
    ) {
      return "Operator requires operand";
    }

    // Check for missing operator between variables or between variable and parenthesis
    if (
      token.type === "VAR" &&
      nextToken &&
      (nextToken.type === "VAR" || nextToken.type === "LPAREN" || nextToken.type === "NOT")
    ) {
      return "Missing operator between terms";
    }

    // Check for missing operator between closing parenthesis and variable or opening parenthesis or NOT
    if (
      token.type === "RPAREN" &&
      nextToken &&
      (nextToken.type === "VAR" || nextToken.type === "LPAREN" || nextToken.type === "NOT")
    ) {
      return "Missing operator after parenthesis group";
    }

    // Check for missing operator after a variable followed by NOT
    if (token.type === "NOT" && i > 0 && tokens[i - 1].type === "VAR") {
      return "Missing operator between variable and NOT";
    }

    // Check for adjacent binary operators
    if (
      (token.type === "AND" || token.type === "OR") &&
      nextToken &&
      (nextToken.type === "AND" || nextToken.type === "OR")
    ) {
      return "Adjacent binary operators";
    }

    // Check for trailing operator
    if ((token.type === "AND" || token.type === "OR") && !nextToken) {
      return "Trailing operator";
    }
  }

  return null; // No syntax errors
}

/**
 * Check if all variables in the formula are from the allowed variables array
 * and if all allowed variables are used
 * @param tokens Tokenized formula
 * @param allowedVariables Array of allowed variable names
 * @returns Object with arrays of invalid and unused variables
 */
function checkVariables(tokens: Token[], allowedVariables: string[]) {
  const invalidVariables: string[] = [];
  const usedVariables = new Set<string>();

  // Check for invalid variables and track used variables
  for (const token of tokens) {
    if (token.type === "VAR") {
      if (!allowedVariables.includes(token.value)) {
        if (!invalidVariables.includes(token.value)) {
          invalidVariables.push(token.value);
        }
      } else {
        usedVariables.add(token.value);
      }
    }
  }

  // Find unused variables
  const unusedVariables = allowedVariables.filter((variable) => !usedVariables.has(variable));

  return { invalidVariables, unusedVariables };
}

export default formSchema;
