import { NavLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import CommitmentManagerGraphicLight from "../../assets/image_first_screen_CM.png";
import CommitmentManagerGraphicDark from "../../assets/image_first_screen_CM_dark.png";
import { homeBullets } from "./consts";

export const Onboarding = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Stack
        direction={{ md: "column", lg: "row" }}
        spacing={8}
        sx={{
          width: { xs: "100%", md: "90%" },
          maxWidth: "1600px",
          margin: "auto",
          padding: 2,
        }}
      >
        <Stack
          sx={{
            flex: 1,
            width: "100%",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontWeight: 500,
              pb: 2,
            }}
          >
            Commitment manager
          </Typography>
          <Stack>
            <Typography
              sx={{
                color: "text.secondary",
                pb: 3,
              }}
            >
              Compare your current cloud spend to your planned spend, all tracked along the baseline of your contracted
              commit spend.
            </Typography>
            {homeBullets.map(({ bold, text }, index) => (
              <Typography
                key={index}
                variant="body1"
                sx={{
                  color: "text.secondary",
                  display: "list-item",
                  listStyleType: "disc",
                  marginLeft: 2,
                  pb: 1,
                  listStylePosition: "inside",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    display: "inline",
                    mr: 1,
                  }}
                >
                  {bold}
                </Typography>
                {text}
              </Typography>
            ))}

            <Button
              component={NavLink}
              variant="contained"
              to="./commitment-manager/create"
              sx={{ mt: 2, width: "fit-content" }}
            >
              Create commitment
            </Button>
          </Stack>
        </Stack>

        <Box
          component="img"
          src={theme.palette.mode === "dark" ? CommitmentManagerGraphicDark : CommitmentManagerGraphicLight}
          alt="Commitment manager graphic"
          sx={{
            maxWidth: "100%",
            height: "auto",
          }}
        />
      </Stack>
    </Box>
  );
};
