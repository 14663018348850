import React, { useState } from "react";

import { type CurrencyCode } from "@doitintl/cmp-models";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { formatValueWithCurrency } from "../../Components/hooks/useFormatter";
import { useFullScreen } from "../../utils/dialog";
import { EligibleText, FlexsaveText, InfoTooltip, MarketplaceText, SupportText, TotalText } from "./Tooltips";
import { type MonthlySpend, type TableData, type TableRow as CommitmentTableRow } from "./types";

export interface CommitmentDataTableProps {
  tableData: TableData;
  monthlySpend: MonthlySpend;
  currencyCode: CurrencyCode;
  marketplaceLimitAmount: number;
}

interface MarketplaceDialogProps {
  open: boolean;
  onClose: () => void;
  monthDate: string;
  breakdown: Record<string, number>;
  currencyCode: CurrencyCode;
  selectedRow: CommitmentTableRow | null;
  selectedMonth: string;
  periodLimit: number;
  previousEligibleMarketplace: number;
}

const MarketplaceDialog = ({
  open,
  onClose,
  monthDate,
  breakdown,
  currencyCode,
  selectedRow,
  selectedMonth,
  periodLimit,
  previousEligibleMarketplace,
}: MarketplaceDialogProps) => {
  const { fullScreen } = useFullScreen();
  const theme = useTheme();

  const eligibleAmount = selectedRow?.data[selectedMonth] ?? 0;
  const servicesSum = breakdown ? Object.values(breakdown).reduce((sum, amount) => sum + amount, 0) : 0;
  const remaining = periodLimit - previousEligibleMarketplace;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      maxWidth="md"
      aria-labelledby="marketplace-breakdown-dialog-title"
    >
      <DialogTitle id="marketplace-breakdown-dialog-title">
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="h6">
            Marketplace Breakdown -{" "}
            {new Date(monthDate).toLocaleDateString(undefined, { month: "long", year: "numeric" })}
          </Typography>
          <InfoTooltip text={MarketplaceText} />
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ maxHeight: "80vh" }}>
        <Stack direction="column" spacing={4}>
          <TableContainer sx={{ maxHeight: "200px", overflow: "auto" }}>
            <Table>
              <TableRow>
                <TableCell>Marketplace limit</TableCell>
                <TableCell align="right">{formatValueWithCurrency(periodLimit, 2, currencyCode, false)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Previous eligible spend</TableCell>
                <TableCell align="right">
                  {formatValueWithCurrency(previousEligibleMarketplace, 2, currencyCode, false)}
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  backgroundColor: theme.palette.general.backgroundDark,
                }}
              >
                <TableCell>Remaining</TableCell>
                <TableCell align="right">{formatValueWithCurrency(remaining, 2, currencyCode, false)}</TableCell>
              </TableRow>
            </Table>
          </TableContainer>

          <Divider />
          {breakdown && Object.keys(breakdown).length > 0 ? (
            <TableContainer sx={{ maxHeight: "300px", overflow: "auto" }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Service</TableCell>
                    <TableCell align="right">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(breakdown).map(([service, amount]) => (
                    <TableRow key={service}>
                      <TableCell>{service}</TableCell>
                      <TableCell align="right">{formatValueWithCurrency(amount, 2, currencyCode, false)}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold", backgroundColor: theme.palette.general.backgroundDark }}>
                      <Stack direction="row" alignItems="center">
                        Total
                        <InfoTooltip text={TotalText} />
                      </Stack>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontWeight: "bold", backgroundColor: theme.palette.general.backgroundDark }}
                    >
                      {formatValueWithCurrency(servicesSum, 2, currencyCode, false)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold", backgroundColor: theme.palette.general.backgroundDark }}>
                      <Stack direction="row" alignItems="center">
                        Eligible (from remaining)
                        <InfoTooltip text={EligibleText} />
                      </Stack>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontWeight: "bold", backgroundColor: theme.palette.general.backgroundDark }}
                    >
                      {formatValueWithCurrency(eligibleAmount, 2, currencyCode, false)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box sx={{ p: 2, textAlign: "center" }}>
              <Typography variant="body1">No marketplace data available for this month.</Typography>
            </Box>
          )}
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const CommitmentDataTable = ({
  tableData,
  monthlySpend,
  currencyCode,
  marketplaceLimitAmount,
}: CommitmentDataTableProps) => {
  const [orderBy, setOrderBy] = React.useState<string>("total");
  const [order, setOrder] = React.useState<"asc" | "desc">("desc");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState<string>("");
  const [selectedRow, setSelectedRow] = useState<CommitmentTableRow | null>(null);
  const theme = useTheme();

  const handleSort = (column: string) => {
    const isAsc = orderBy === column && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(column);
  };

  const handleCellClick = (row: CommitmentTableRow, month: string) => {
    if (row.type === "marketplace") {
      setSelectedMonth(month);
      setSelectedRow(row);
      setDialogOpen(true);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const sortedRows = React.useMemo(() => {
    if (!orderBy) return tableData.rows;

    return [...tableData.rows].sort((a, b) => {
      let comparison = 0;

      if (orderBy === "name") {
        comparison = a.name.localeCompare(b.name);
      } else if (orderBy === "total") {
        comparison = (a.total ?? 0) - (b.total ?? 0);
      } else {
        comparison = (a.data[orderBy] ?? 0) - (b.data[orderBy] ?? 0);
      }

      return order === "asc" ? comparison : -comparison;
    });
  }, [tableData.rows, orderBy, order]);

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 400,
          "& .sticky-row": {
            position: "sticky",
            bottom: 0,
            boxShadow: (theme) =>
              theme.palette.mode === "dark"
                ? "0px -3px 8px rgba(255, 255, 255, 0.15)"
                : "0px -2px 4px rgba(0, 0, 0, 0.1)",
            zIndex: 1,
          },
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "name"}
                  direction={orderBy === "name" ? order : "asc"}
                  onClick={() => {
                    handleSort("name");
                  }}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              {tableData.months.map((yearMonth: string) => (
                <TableCell key={yearMonth}>
                  <TableSortLabel
                    active={orderBy === yearMonth}
                    direction={orderBy === yearMonth ? order : "asc"}
                    onClick={() => {
                      handleSort(yearMonth);
                    }}
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    {new Date(yearMonth).toLocaleDateString(undefined, { month: "short", year: "2-digit" })}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell>
                <TableSortLabel
                  active={orderBy === "total"}
                  direction={orderBy === "total" ? order : "asc"}
                  onClick={() => {
                    handleSort("total");
                  }}
                >
                  Total
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows.map((row) => (
              <TableRow key={`${row.type}-${row.name}`}>
                <TableCell>
                  {row.type === "marketplace" ? (
                    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                      {row.name}
                      <InfoTooltip text={MarketplaceText} />
                    </Box>
                  ) : row.type === "service" && row.name === "Flexsave covered costs*" ? (
                    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                      {row.name}
                      <InfoTooltip text={FlexsaveText} />
                    </Box>
                  ) : row.type === "service" &&
                    (row.name === "Support*" || row.name === "AWS Support (Enterprise)*") ? (
                    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                      {row.name}
                      <InfoTooltip text={SupportText} />
                    </Box>
                  ) : (
                    row.name
                  )}
                </TableCell>
                {tableData.months.map((yearMonth: string) => (
                  <TableCell
                    key={`${row.type}-${row.name}-${yearMonth}`}
                    onClick={() => {
                      handleCellClick(row, yearMonth);
                    }}
                    sx={
                      row.type === "marketplace"
                        ? {
                            cursor: "pointer",
                            textDecoration: "underline",
                            color: theme.palette.primary.main,
                            "&:hover": {
                              backgroundColor: theme.palette.action.hover,
                              textDecoration: "underline",
                            },
                          }
                        : {}
                    }
                  >
                    {formatValueWithCurrency(row.data[yearMonth] ?? 0, 2, currencyCode, true)}
                  </TableCell>
                ))}
                <TableCell sx={{ backgroundColor: theme.palette.general.backgroundDark }}>
                  {formatValueWithCurrency(row.total ?? 0, 2, currencyCode, true)}
                </TableCell>
              </TableRow>
            ))}
            <TableRow className="sticky-row">
              <TableCell sx={{ backgroundColor: theme.palette.general.backgroundDark }}>
                {tableData.totalRow.name}
              </TableCell>
              {tableData.months.map((yearMonth: string) => (
                <TableCell key={`total-${yearMonth}`} sx={{ backgroundColor: theme.palette.general.backgroundDark }}>
                  {formatValueWithCurrency(tableData.totalRow.data[yearMonth] ?? 0, 2, currencyCode, true)}
                </TableCell>
              ))}
              <TableCell sx={{ backgroundColor: theme.palette.general.backgroundDark }}>
                {formatValueWithCurrency(tableData.totalRow.total ?? 0, 2, currencyCode, true)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {dialogOpen && selectedMonth && monthlySpend[selectedMonth] && (
        <MarketplaceDialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          monthDate={selectedMonth}
          breakdown={monthlySpend[selectedMonth].marketplace || {}}
          currencyCode={currencyCode}
          selectedRow={selectedRow}
          selectedMonth={selectedMonth}
          periodLimit={marketplaceLimitAmount}
          previousEligibleMarketplace={tableData.marketplaceEligableData[selectedMonth] ?? 0}
        />
      )}
    </>
  );
};
