import { useCallback, useState } from "react";

import { type Label } from "@doitintl/cmp-models";
import { Box, Button, DialogActions, DialogContent, MenuItem, Stack, TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

import { labelsText } from "../../../assets/texts";
import LoadingButton from "../../../Components/LoadingButton";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { LABEL_COLORS } from "./consts";
import { useCreateLabel, useUpdateLabel } from "./hooks";
import { type LabelRowItem } from "./types";

export interface LabelDialogProps {
  open: boolean;
  selectedLabel?: LabelRowItem;
  onClose: () => void;
}

const LabelDialog = ({ selectedLabel, onClose, open }: LabelDialogProps) => {
  const [label, setLabel] = useState<Label>(selectedLabel?.data ?? ({ name: "", color: "" } as any));
  const createLabel = useCreateLabel();
  const updateLabel = useUpdateLabel();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const [loading, setLoading] = useState(false);

  const onUpdateLabelField = useCallback(
    (field: string, value: string) => {
      setLabel({ ...label, [field]: value });
    },
    [label]
  );

  const onSuccess = useCallback(
    (message: string) => {
      successSnackbar(message);
      onClose();
    },
    [onClose, successSnackbar]
  );

  const onError = useCallback(
    (message: string) => {
      errorSnackbar(message);
    },
    [errorSnackbar]
  );

  const onSave = useCallback(async () => {
    setLoading(true);
    if (!selectedLabel) {
      await createLabel(
        label,
        () => {
          onSuccess(labelsText.CREATE_LABEL_SUCCESS);
        },
        () => {
          onError(labelsText.CREATE_LABEL_ERROR);
        }
      );
    } else {
      await updateLabel(
        selectedLabel.ref.id,
        label,
        () => {
          onSuccess(labelsText.UPDATE_LABEL_SUCCESS);
        },
        () => {
          onError(labelsText.UPDATE_LABEL_ERROR);
        }
      );
    }
    setLoading(false);
  }, [createLabel, label, onError, onSuccess, selectedLabel, updateLabel]);

  return (
    <Dialog fullWidth onClose={onClose} open={open} maxWidth="xs" data-cy="label-dialog">
      <DialogTitle>{selectedLabel ? "Edit labels" : "New label"}</DialogTitle>
      {/* using style instead of sx due to this issue https://github.com/mui/material-ui/issues/27851 */}
      <DialogContent style={{ paddingTop: "0.5em" }}>
        <Stack spacing={2}>
          <TextField
            variant="outlined"
            label="Label name"
            value={label.name}
            onChange={(event) => {
              onUpdateLabelField("name", event.target.value);
            }}
          />
          <TextField
            select
            variant="outlined"
            label="Label color"
            value={label.color}
            onChange={(event) => {
              onUpdateLabelField("color", event.target.value);
            }}
          >
            {LABEL_COLORS.map((option) => (
              <MenuItem key={option.name} value={option.color}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      width: "1em",
                      height: "1em",
                      borderRadius: "50%",
                      bgcolor: option.color,
                    }}
                  />
                  <Typography
                    sx={{
                      pl: 1,
                    }}
                  >
                    {option.name}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <LoadingButton variant="contained" onClick={onSave} loading={loading} mixpanelEventId="analytics.labels.save">
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default LabelDialog;
