import { type Collaborator, Roles } from "@doitintl/cmp-models";

import { type SegmentEventProperties } from "../../../utils/useSegmentTrackEvent";
import { type AnalyticsResourcesAttributionGroups } from "../analyticsResources/types";
import { isOwner } from "../utilities";
import { type AttributionGroupWithRef } from "./types";

export const getAttributionsIDsFromAG = (
  ag: AttributionGroupWithRef | AnalyticsResourcesAttributionGroups | undefined
): string[] => ag?.data.attributions.map((attribution) => attribution.id) ?? [];

export type TrackAllocationEventParams = {
  trackEvent: (eventName: string, properties?: SegmentEventProperties) => void;
  eventName: string;
  ruleCount: number;
  hasUnallocatedCosts: boolean;
  allocationId?: string;
  currentUserEmail?: string;
  collaborators?: Collaborator[];
  additionalProps?: Record<string, unknown>;
};

export const trackAllocationEvent = ({
  trackEvent,
  eventName,
  ruleCount,
  hasUnallocatedCosts,
  allocationId,
  currentUserEmail,
  collaborators,
  additionalProps,
}: TrackAllocationEventParams): void => {
  const props = {
    pageType: "CloudAnalytics",
    pageSubType: "Allocations",
    feature: "Allocations",
    rulesCount: ruleCount,
    allocationNew: true,
    ...(allocationId && {
      allocationId,
      allocationNew: false,
      allocationType: ruleCount > 1 || hasUnallocatedCosts ? "group" : "single",
    }),
    ...(currentUserEmail && {
      ruleOriginalOwner: isOwner(currentUserEmail, {
        collaborators: collaborators || [{ email: currentUserEmail, role: Roles.OWNER }],
      }),
    }),
    ...additionalProps,
  };

  trackEvent(eventName, props);
};

export type TrackAllocationBrowserEventParams = {
  trackEvent: (eventName: string, properties?: SegmentEventProperties) => void;
  eventName: string;
  allocationId?: string;
  ruleCount?: number;
  hasUnallocatedCosts?: boolean;
  collaborators?: Collaborator[];
  currentUserEmail?: string;
  additionalProps?: Record<string, unknown>;
};

export const trackAllocationBrowserEvent = ({
  trackEvent,
  eventName,
  allocationId,
  additionalProps,
  ruleCount = 0,
  collaborators,
  hasUnallocatedCosts = false,
  currentUserEmail,
}: TrackAllocationBrowserEventParams): void => {
  const props = {
    pageType: "CloudAnalytics",
    pageSubType: "AllocationsBrowser",
    feature: "Allocations",
    ...(allocationId && {
      allocationId,
      rulesCount: ruleCount,
      hasUnallocatedCosts,
      allocationType: ruleCount > 1 || hasUnallocatedCosts ? "group" : "single",
      collaborators,
    }),
    ...(currentUserEmail && {
      ruleOriginalOwner: isOwner(currentUserEmail, {
        collaborators: collaborators || [{ email: currentUserEmail, role: Roles.OWNER }],
      }),
    }),
    ...additionalProps,
  };

  trackEvent(eventName, props);
};

/**
 * Updates a formula by:
 * 1. Removing variables not in the provided list
 * 2. Removing parentheses when they contain the last remaining value
 * 3. Adding variables from the list that aren't in the formula (with AND operators)
 *
 * @param formula The original formula string
 * @param variables List of valid variables to keep or add
 * @returns Updated formula string
 */
function updateFormula(formula: string, variables: string[]): string {
  // Step 1: Tokenize the formula
  const tokens = tokenizeFormula(formula);

  // Step 2: Filter and process tokens
  let result = processTokens(tokens, variables);

  // Step 3: Add missing variables with AND operators
  const existingVars = new Set<string>();
  for (const token of tokens) {
    if (variables.includes(token)) {
      existingVars.add(token);
    }
  }

  // Add missing variables with AND operators
  for (const variable of variables) {
    if (!existingVars.has(variable)) {
      if (result) {
        result = `${result} AND ${variable}`;
      } else {
        result = variable;
      }
    }
  }

  return result;
}

/**
 * Tokenizes a formula string into an array of tokens
 */
export function tokenizeFormula(formula: string): string[] {
  // Regular expression to match variables, operators, and parentheses
  const regex = /([A-Z]+|\(|\)|\s+|AND|NOT|OR)/g;

  // Split the formula into tokens
  const tokens: string[] = formula.match(regex) || [];

  // Remove whitespace tokens and trim remaining tokens
  const filteredTokens = tokens.map((token) => token.trim()).filter((token) => token !== "");
  return filteredTokens;
}

/**
 * Process tokens to remove invalid variables and simplify parentheses
 */
export function processTokens(tokens: string[], variables: string[]): string {
  // Recursively process expressions
  const processExpression = (startIndex: number): [string, number] => {
    const result: string[] = [];
    let i = startIndex;

    // Handle NOT at the beginning of an expression
    if (i < tokens.length && tokens[i] === "NOT") {
      result.push("NOT");
      i++;
    }

    while (i < tokens.length && tokens[i] !== ")") {
      if (tokens[i] === "(") {
        // Process sub-expression
        const [subResult, newIndex] = processExpression(i + 1);
        if (subResult) {
          result.push(subResult);
        }
        i = newIndex + 1;
      } else if (tokens[i] === "AND" || tokens[i] === "OR") {
        // Keep operators if there are valid terms on both sides
        if (
          result.length > 0 &&
          i + 1 < tokens.length &&
          (variables.includes(tokens[i + 1]) || tokens[i + 1] === "(" || tokens[i + 1] === "NOT")
        ) {
          result.push(tokens[i]);
        }
        i++;
      } else if (tokens[i] === "NOT") {
        // Handle NOT in the middle of an expression
        if (i + 1 < tokens.length && (variables.includes(tokens[i + 1]) || tokens[i + 1] === "(")) {
          result.push("NOT");
        }
        i++;
      } else if (variables.includes(tokens[i])) {
        // Keep valid variables
        result.push(tokens[i]);
        i++;
      } else {
        // Skip invalid variables
        i++;
      }
    }

    // Clean up result
    if (result.length === 0) {
      return ["", i];
    }

    // Remove trailing operators
    const lastToken = result[result.length - 1];
    if (lastToken === "AND" || lastToken === "OR" || lastToken === "NOT") {
      result.pop();
    }

    // Handle single value case - remove parentheses
    if (result.length === 1) {
      return [result[0], i];
    }

    // Keep parentheses for multiple values
    return [`(${result.join(" ")})`, i];
  };

  const [result, _] = processExpression(0);
  // Remove outermost parentheses if present
  return result.replace(/^\((.*)\)$/, "$1");
}
export { updateFormula };

export type TrackAllocationDeleteEventParams = {
  trackEvent: (eventName: string, properties?: SegmentEventProperties) => void;
  allocationIds: string[];
  currentUserEmail?: string;
};

export const trackAllocationDeleteEvent = ({
  trackEvent,
  allocationIds,
  currentUserEmail,
}: TrackAllocationDeleteEventParams): void => {
  const props = {
    pageType: "CloudAnalytics",
    pageSubType: "AllocationsBrowser",
    feature: "Allocations",
    allocationIds,
    currentUserEmail,
  };

  trackEvent("Allocations Deleted", props);
};
