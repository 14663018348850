import { useCallback, useEffect, useState } from "react";

import { type AttributionFilter, type ContractType, Metadata, MetadataCloud } from "@doitintl/cmp-models";
import { FormHelperText, Stack, Typography } from "@mui/material";
import { useFormikContext } from "formik";

import { useDimension } from "../../../Components/Dialogs/CloudAnalytics/hooks";
import { Loader } from "../../../Components/Loader";
import { FixedFilters } from "../../CloudAnalytics/utilities";
import { AttributeSelector } from "../AttributeSelector";

interface CommitmentStepValues {
  billingAccounts: string[];
  serviceFilter: AttributionFilter;
  skuFilter: AttributionFilter;
  contractType: ContractType;
}

export const ConfigurationStep = () => {
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext<CommitmentStepValues>();
  const [isLoading, setIsLoading] = useState(true);

  const cloudProviders = (() => {
    switch (values.contractType) {
      case "amazon-web-services":
        return [MetadataCloud.AMAZON_WEB_SERVICES];
      case "google-cloud":
        return [MetadataCloud.GOOGLE_CLOUD];
      default:
        return undefined;
    }
  })();

  const billingAccountDimensionId =
    values.contractType === "amazon-web-services"
      ? `${Metadata.SYSTEM_LABEL}:YXdzL3BheWVyX2FjY291bnRfaWQ=`
      : `${Metadata.FIXED}:${FixedFilters.ACCOUNT}`;

  const billingAccountFilter: Partial<AttributionFilter> = {
    inverse: false,
    values: values.billingAccounts,
  };

  const billingAccountDimension = useDimension(billingAccountDimensionId);
  const serviceDimension = useDimension(`${Metadata.FIXED}:${FixedFilters.SERVICE}`);
  const skuDimension = useDimension(`${Metadata.FIXED}:${FixedFilters.SKU}`);

  useEffect(() => {
    if (billingAccountDimension && serviceDimension && skuDimension) {
      setIsLoading(false);
    }
  }, [billingAccountDimension, serviceDimension, skuDimension]);

  const handleBillingAccountFilterChange = useCallback(
    (filter: AttributionFilter) => {
      setFieldValue("billingAccounts", filter.values || [], true);
      if (filter.values && filter.values.length > 0) {
        setFieldTouched("billingAccounts", true, false);
      }
    },
    [setFieldValue, setFieldTouched]
  );

  return (
    <Stack spacing={3} pt={3}>
      <Typography variant="h4">Configuration</Typography>
      <Loader loading={isLoading}>
        <Stack spacing={3}>
          <Stack>
            <Typography pb={0.5} variant="subtitle1" fontWeight={500}>
              {values.contractType === "amazon-web-services" ? "Select payer accounts" : "Select billing accounts"}
              <Typography component="span" color="error" display="inline" ml={0.5}>
                *
              </Typography>
            </Typography>
            <Typography variant="body2" color="text.secondary" pb={1.5}>
              Select all {values.contractType === "amazon-web-services" ? "payer accounts" : "billing accounts"} which
              apply to this commitment
            </Typography>
            <AttributeSelector
              cloudProviders={cloudProviders}
              dimension={billingAccountDimension}
              value={billingAccountFilter as AttributionFilter}
              onChange={handleBillingAccountFilterChange}
              showInverseSelection={false}
              disableRegexpFilter={true}
            />
            {errors.billingAccounts && touched.billingAccounts && (
              <FormHelperText error>{errors.billingAccounts}</FormHelperText>
            )}
          </Stack>
          <Stack>
            <Typography fontWeight={500} variant="subtitle1" pb={0.5}>
              Services and SKUs
            </Typography>
            <Stack spacing={1.5}>
              <Typography variant="body2" color="text.secondary">
                Include or exclude services and SKUs from this commitment.
              </Typography>
              <Stack>
                <AttributeSelector
                  cloudProviders={cloudProviders}
                  dimension={serviceDimension}
                  value={values.serviceFilter}
                  onChange={(filter) => setFieldValue("serviceFilter", filter)}
                />
                <Typography color="text.secondary" variant="caption">
                  Select which services {values.serviceFilter.inverse ? "shouldn't" : "should"} be included
                </Typography>
              </Stack>
              <Stack>
                <AttributeSelector
                  cloudProviders={cloudProviders}
                  dimension={skuDimension}
                  value={values.skuFilter}
                  onChange={(filter) => setFieldValue("skuFilter", filter)}
                />
                <Typography color="text.secondary" variant="caption">
                  Select which SKUs {values.skuFilter.inverse ? "shouldn't" : "should"} be included
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Loader>
    </Stack>
  );
};
