import { type AttributionFilter } from "@doitintl/cmp-models";
import isEqual from "lodash/isEqual";
import * as yup from "yup";
import { create, type StoreApi, type UseBoundStore } from "zustand";

import { type AttributionWRef } from "../../../../types";
import formSchema from "../validationSchema";

type FormValues = {
  id?: string | null;
  name: string;
  filterFields: AttributionFilter[];
  formula: string;
};

export type ViewType = "new" | "existing";

export type FormState = {
  values: FormValues;
  originalValues: FormValues | null;
  errors: Record<string, string | Record<string, string>[]>;
  isSubmitting: boolean;
  isEditMode: boolean;
  isDirty: boolean;
  isValid: boolean;
  viewType: ViewType;
  selectedExistingRule: AttributionWRef | null;
  editWarnings: string;
};

export type Rule = {
  values: FormValues;
  selectedExistingRule: AttributionWRef | null;
};

export type FormActions = {
  setName: (name: string) => void;
  setFilterFields: (fields: AttributionFilter[]) => void;
  setFormula: (formula: string) => void;
  resetForm: () => void;
  setEditMode: (data: FormValues) => void;
  exitEditMode: () => void;
  setSelectedExistingRule: (allocation: AttributionWRef | null) => void;
  setViewType: (viewType: ViewType) => void;
  validateForm: () => void;
  setEditWarnings: (value: string) => void;
};

const initialValues: FormValues = {
  id: null,
  name: "",
  filterFields: [],
  formula: "",
};

const checkFormDirty = (newValues: FormValues, originalValues: FormValues | null): boolean =>
  !isEqual(newValues, originalValues ?? initialValues);

const createValueSetter =
  <K extends keyof FormValues>(key: K) =>
  (set: StoreApi<FormState>["setState"], get: StoreApi<FormState>["getState"]) =>
  async (value: FormValues[K]) => {
    set((state: FormState) => {
      const newValues = {
        ...state.values,
        [key]: value,
      };

      const { selectedExistingRule, viewType } = state;
      const isEditSelected = viewType === "existing" && !!selectedExistingRule;
      const originalValues = isEditSelected
        ? {
            id: selectedExistingRule?.ref.id,
            name: selectedExistingRule?.data.name,
            filterFields: selectedExistingRule?.data.filters || [],
            formula: selectedExistingRule?.data.formula || "",
          }
        : state.originalValues;

      const isDirty = checkFormDirty(newValues, originalValues);

      return {
        values: newValues,
        isDirty,
      };
    });

    // Run validation after state update
    try {
      const state = get();
      await formSchema.validate(state.values, { abortEarly: false });
      set({ isValid: true, errors: {} });
      // If validation passes, update external save permission store
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const errors = error.inner.reduce(
          (acc, err) => ({
            ...acc,
            ...(err.path ? { [err.path]: err.message } : {}),
          }),
          {}
        );
        set({ isValid: false, errors });
      }
    }
  };

const createFormStore = () =>
  create<FormState & FormActions>((set, get) => {
    const initialState: FormState = {
      values: initialValues,
      originalValues: null,
      errors: {},
      isSubmitting: false,
      isEditMode: false,
      isDirty: false,
      isValid: false,
      viewType: "new",
      selectedExistingRule: null,
      editWarnings: "",
    };

    const actions: FormActions = {
      setName: createValueSetter("name")(set, get),
      setFilterFields: createValueSetter("filterFields")(set, get),
      setFormula: createValueSetter("formula")(set, get),

      setSelectedExistingRule: (allocation: AttributionWRef | null) => {
        set((state) => ({ selectedExistingRule: allocation, values: { ...state.values, id: allocation?.ref.id } }));
      },

      setEditWarnings: (value: string) => {
        set(() => ({ editWarnings: value }));
      },

      setViewType: (viewType: ViewType) => {
        set({ viewType });
        if (viewType === "new") {
          set({ values: { id: null, name: "", filterFields: [], formula: "" }, selectedExistingRule: null });
        } else {
          set({ values: { ...get().values, id: get().selectedExistingRule?.ref.id } });
        }
      },

      setEditMode: (data: FormValues) => {
        set({
          ...initialState,
          values: data,
          originalValues: data,
          isEditMode: true,
        });
      },

      exitEditMode: () => {
        set(initialState);
      },

      resetForm: () => {
        const state = get();
        set({
          ...initialState,
          ...(state.isEditMode && state.originalValues ? { values: state.originalValues } : {}),
        });
      },

      validateForm: async () => {
        const state = get();
        try {
          await formSchema.validate(state.values, { abortEarly: false });
          set({ isValid: true, errors: {} });
        } catch (error) {
          if (error instanceof yup.ValidationError) {
            const errors = error.inner.reduce(
              (acc, err) => ({
                ...acc,
                ...(err.path ? { [err.path]: err.message } : {}),
              }),
              {}
            );
            set({ isValid: false, errors });
          }
        }
      },
    };

    return {
      ...initialState,
      ...actions,
    };
  });

class FormStoreManager {
  private stores: Map<string, UseBoundStore<StoreApi<FormState & FormActions>>> = new Map();

  getStore(id: string) {
    if (!this.stores.has(id)) {
      this.stores.set(id, createFormStore());
    }
    return this.stores.get(id)!;
  }

  removeStore(id: string) {
    this.stores.delete(id);
  }

  clear() {
    this.stores.clear();
  }
}

export const formStoreManager = new FormStoreManager();
