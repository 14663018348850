import { useEffect, useRef } from "react";

import {
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import { type DateTime } from "luxon";

import { defaultMarketplaceLimitPercentage } from "./consts";
import { CustomTextField } from "./CustomTextField";
import { HeaderCell } from "./HeaderCell";
import { NumberInput } from "./NumberInput";
import { CommitmentValueText, InfoTooltip, MarketplaceText } from "./Tooltips";
import { type CommitmentPeriod } from "./types";
import { addMonths, formatDate } from "./utils";

interface CommitmentValues {
  periods: CommitmentPeriod[];
}

type Props = {
  numberOfPeriods: number;
  startDate: DateTime;
};

export const CommitmentTable = ({ numberOfPeriods, startDate }: Props) => {
  const { values, handleChange, errors, touched } = useFormikContext<CommitmentValues>();
  const previousStartDate = useRef(startDate);
  const previousPeriodsLength = useRef(values.periods.length);

  useEffect(() => {
    if (numberOfPeriods < 0) {
      return;
    }
    const prevNum = previousPeriodsLength.current;
    if (prevNum < numberOfPeriods) {
      const currentDate = prevNum === 0 ? startDate.toJSDate() : values.periods[prevNum - 1].endDate;
      const newCommitment: CommitmentPeriod = {
        startDate: currentDate,
        endDate: addMonths(currentDate, 12),
        periodLength: 12,
        commitmentValue: "",
        marketplaceLimitPercentage: defaultMarketplaceLimitPercentage,
      };
      const newCommitments = [...values.periods, newCommitment];
      handleChange({ target: { name: "periods", value: newCommitments } });
    }
    if (prevNum > numberOfPeriods) {
      const newCommitments = values.periods.slice(0, numberOfPeriods);
      handleChange({ target: { name: "periods", value: newCommitments } });
    }
    previousPeriodsLength.current = values.periods.length;
  }, [numberOfPeriods, handleChange, values.periods, startDate]);

  useEffect(() => {
    if (values.periods.length === 0 || !startDate) return;

    if (previousStartDate.current.toMillis() === startDate.toMillis()) {
      return;
    }
    previousStartDate.current = startDate;

    let auxDate = startDate.toJSDate();
    const updatedCommitments = values.periods.map((commitment) => {
      const newCommitment = {
        ...commitment,
        startDate: auxDate,
        endDate: addMonths(auxDate, commitment.periodLength),
      };
      auxDate = newCommitment.endDate;
      return newCommitment;
    });

    handleChange({ target: { name: "periods", value: updatedCommitments } });
  }, [startDate, handleChange, values.periods]);

  if (numberOfPeriods === 0) {
    return null;
  }

  const handleChangePeriod = (index: number, value: number) => {
    let d = values.periods[0].startDate;
    const newCommitments = values.periods.map((commitment, i) => {
      if (i === index) {
        d = addMonths(commitment.startDate, value);
        return {
          ...commitment,
          periodLength: value,
          endDate: d,
        };
      } else if (i > index) {
        return {
          ...commitment,
          startDate: d,
          endDate: addMonths(d, commitment.periodLength),
        };
      }

      return commitment;
    });
    handleChange({ target: { name: "periods", value: newCommitments } });
  };

  const handleChangeValue = (index: number, value: string) => {
    const newCommitments = values.periods.map((commitment, i) => {
      if (i === index) {
        return { ...commitment, commitmentValue: value };
      }
      return commitment;
    });
    handleChange({ target: { name: "periods", value: newCommitments } });
  };

  const handleChangeMarketplaceLimitPercentage = (index: number, value: string) => {
    const newCommitments = values.periods.map((commitment, i) => {
      if (i === index) {
        return { ...commitment, marketplaceLimitPercentage: value };
      }
      return commitment;
    });
    handleChange({ target: { name: "periods", value: newCommitments } });
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <HeaderCell>Period</HeaderCell>
            <HeaderCell>Start & end date</HeaderCell>
            <HeaderCell>
              Period length (months)
              <Typography component="span" color="error" display="inline" ml={0.5}>
                *
              </Typography>
            </HeaderCell>
            <TableCell>
              <Stack direction="row" alignItems="center">
                <Typography variant="caption">
                  Commitment value
                  <Typography component="span" color="error" display="inline" ml={0.5}>
                    *
                  </Typography>
                </Typography>
                <InfoTooltip text={CommitmentValueText} />
              </Stack>
            </TableCell>
            <TableCell>
              <Stack direction="row" alignItems="center">
                <Typography variant="caption">
                  Marketplace spend
                  <Typography component="span" color="error" display="inline" ml={0.5}>
                    *
                  </Typography>
                </Typography>
                <InfoTooltip text={MarketplaceText} />
              </Stack>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.periods.slice(0, numberOfPeriods).map((commitment, i) => (
            <TableRow key={i}>
              <TableCell>{i + 1}</TableCell>
              <TableCell>
                {formatDate(commitment.startDate)} - {formatDate(commitment.endDate)}
              </TableCell>
              <TableCell sx={{ width: { lg: 200 }, verticalAlign: "top" }}>
                <CustomTextField
                  value={commitment.periodLength.toString()}
                  onChange={(e) => {
                    handleChangePeriod(i, Number(e.target.value));
                  }}
                  errors={errors.periods?.[i]}
                  touched={touched.periods?.[i]}
                  name="periodLength"
                  type="number"
                />
              </TableCell>

              <TableCell sx={{ verticalAlign: "top" }}>
                <NumberInput
                  value={commitment.commitmentValue}
                  onChange={(value) => {
                    handleChangeValue(i, value);
                  }}
                  errors={errors.periods?.[i]}
                  touched={touched.periods?.[i]}
                  name="commitmentValue"
                />
              </TableCell>
              <TableCell sx={{ verticalAlign: "top" }}>
                <CustomTextField
                  value={commitment.marketplaceLimitPercentage}
                  onChange={(e) => {
                    handleChangeMarketplaceLimitPercentage(i, e.target.value);
                  }}
                  errors={errors.periods?.[i]}
                  touched={touched.periods?.[i]}
                  name="marketplaceLimitPercentage"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>, // Add "%" suffix
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
