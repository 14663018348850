import { type Adjustment, type CommitmentManagersModel, type Period, type PeriodSpend } from "@doitintl/cmp-models";
import { DateTime } from "luxon";
import * as Yup from "yup";

import { type MetadataOption } from "../../types";
import { assetTypeName } from "../../utils/common";
import { type DataPoint, type MonthlySpend, type ProcessedPeriod, type SeriesData, type TableRow } from "./types";

const step0 = Yup.object({
  contractType: Yup.string().required("Provider selection is required"),
  name: Yup.string().required("Commitment manager name is required"),
  selectedContractIds: Yup.array(),
  currency: Yup.string().required("Currency is required"),
});
const step1 = Yup.object({
  startDate: Yup.string().required("Start date is required"),
  periods: Yup.array().of(
    Yup.object().shape({
      periodLength: Yup.number().max(100, "Value must be under 100").required("Period length is required"),
      commitmentValue: Yup.number().typeError("Must be a number").required("Commitment value is required"),
      marketplaceLimitPercentage: Yup.number()
        .typeError("Must be a number")
        .min(0, "Must be between 0 and 100")
        .max(100, "Must be between 0 and 100")
        .required("Marketplace spend value is required"),
    })
  ),
  excess: Yup.boolean(),
  excessValue: Yup.number()
    .typeError("Must be a number")
    .when("excess", {
      is: true,
      then: (schema) =>
        schema
          .min(1, "Value must be at least 1")
          .max(100, "Value must be under 100")
          .required("Excess value is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  shortfall: Yup.boolean(),
  shortfallValue: Yup.number()
    .typeError("Must be a number")
    .when("shortfall", {
      is: true,
      then: (schema) =>
        schema
          .min(1, "Value must be at least 1")
          .max(100, "Value must be under 100")
          .required("Shortfall value is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
});
const step2 = Yup.object({
  billingAccounts: Yup.array()
    .required("Billing accounts are required")
    .min(1, "At least one billing account must be selected"),
});
export const validationSchema = [step0, step1, step2];

export const formatDate = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = { day: "2-digit", month: "short", year: "numeric" };
  return date.toLocaleDateString(undefined, options);
};

export const addMonths = (date: Date, months: number) => {
  const newDate = new Date(date);
  newDate.setMonth(date.getMonth() + months);
  return newDate;
};

export const getFieldName = (selected: MetadataOption) => {
  const label = selected.data.label ?? selected.data.field;
  const plural = selected.data.plural ?? label;
  return plural;
};

export function getDaysBetween(startDate: Date, endDate: Date): number {
  const start = DateTime.fromJSDate(startDate);
  const end = DateTime.fromJSDate(endDate);
  const diff = end.diff(start, "days").days;
  return Math.max(0, diff);
}

export function processBreakdown(
  data: Record<string, Record<string, number>>,
  source: Record<string, number> = {},
  month: string
): void {
  Object.entries(source).forEach(([key, value]) => {
    data[key] = data[key] || {};
    data[key][month] = value;
  });
}

export function processCommitmentPeriods(
  commitmentManager: CommitmentManagersModel | undefined | null
): ProcessedPeriod[] {
  if (!commitmentManager?.periodsSpend) return [];

  return commitmentManager.periodsSpend.map((periodSpend, periodIndex) => {
    const monthlySpend = periodSpend.MonthlySpend;
    const months = Object.keys(monthlySpend).sort();

    const servicesData: Record<string, Record<string, number>> = {}; // <name, <month, spend>>
    const creditsData: Record<string, Record<string, number>> = {};
    const marketplaceData: Record<string, number> = {}; // <month, spend>
    const marketplaceEligableData: Record<string, number> = {}; // <month, eligable>
    const totalsData: Record<string, number> = {};

    let previousEligableMarketplace = 0;
    months.forEach((month) => {
      const breakdown = monthlySpend[month];

      marketplaceData[month] = breakdown?.marketplaceTotal ?? 0;
      marketplaceEligableData[month] = previousEligableMarketplace;
      previousEligableMarketplace += breakdown?.marketplaceTotal ?? 0;

      totalsData[month] = breakdown?.total ?? 0;

      processBreakdown(servicesData, breakdown?.services, month);
      processBreakdown(creditsData, breakdown?.credits, month);
    });

    const rows = [...createCategoryRows(servicesData, "service"), ...createCategoryRows(creditsData, "credit")];

    const totalRow = {
      name: "Total",
      type: "total",
      data: totalsData,
      total: Object.values(totalsData).reduce((sum, val) => sum + val, 0),
    };

    const marketplaceRow = {
      name: "Marketplace",
      type: "marketplace",
      data: marketplaceData,
      total: Object.values(marketplaceData).reduce((sum, val) => sum + val, 0),
    };

    if (marketplaceRow.total > 0) {
      rows.push(marketplaceRow);
    }

    const seriesData = calculateSeriesData(months, monthlySpend, commitmentManager.periods[periodIndex]);

    const tableData = {
      months,
      rows,
      totalRow,
      marketplaceEligableData,
    };

    return {
      tableData,
      seriesData,
    };
  });
}

function createCategoryRows(categoryData: Record<string, Record<string, number>>, type: string): TableRow[] {
  return Object.keys(categoryData).map((name) => ({
    name,
    type,
    data: categoryData[name],
    total: Object.values(categoryData[name]).reduce((sum, val) => sum + val, 0),
  }));
}

function calculateSeriesData(months: string[], monthlySpend: MonthlySpend, period: Period): SeriesData {
  const now = DateTime.now();
  return months.reduce(
    (acc, yearMonth) => {
      const [year, month] = yearMonth.split("-").map(Number);
      const x = Date.UTC(year, month - 1);

      const isFirstMonth = yearMonth === months[0];
      const isLastMonth = yearMonth === months[months.length - 1];

      acc.commitmentTotal = calculateMonthlyCommitment(
        isFirstMonth,
        isLastMonth,
        year,
        month,
        period,
        acc.commitmentTotal
      );

      if (now > DateTime.fromObject({ year, month })) {
        acc.total += monthlySpend[yearMonth]?.total ?? 0;
        acc.marketplaceTotal += monthlySpend[yearMonth]?.marketplaceTotal ?? 0;

        acc.totalEligible.push({ x, y: acc.total });
        acc.marketplace.push({ x, y: acc.marketplaceTotal });
      }
      acc.commitment.push({ x, y: acc.commitmentTotal });

      return acc;
    },
    {
      total: 0,
      marketplaceTotal: 0,
      commitmentTotal: 0,
      totalEligible: [] as DataPoint[],
      marketplace: [] as DataPoint[],
      commitment: [] as DataPoint[],
    }
  );
}

function calculateMonthlyCommitment(
  isFirstMonth: boolean,
  isLastMonth: boolean,
  year: number,
  month: number,
  period: Period,
  currentCommitmentTotal: number
): number {
  if (isLastMonth) {
    return period.commitmentValue ?? currentCommitmentTotal;
  }

  let daysInMonth =
    DateTime.fromObject({ year, month }).daysInMonth ??
    (() => {
      throw new Error("Invalid month");
    })();

  if (isFirstMonth) {
    const startDay = period.startDate.toDate().getDate();
    daysInMonth = daysInMonth - startDay + 1; // days from start date to end of month
  }

  const totalPeriodDays = getDaysBetween(period.startDate.toDate(), period.endDate.toDate());
  const commitPerDay = Number((period.commitmentValue ?? 0) / totalPeriodDays);

  return Math.round(currentCommitmentTotal + commitPerDay * daysInMonth);
}

export function getContractName(type: string, startDate: Date, endDate?: Date) {
  return `${assetTypeName(type)} - ${formatDate(startDate)} ${endDate ? `-> ${formatDate(endDate)}` : ""}`;
}

export const getPeriodStatus = (startDate: Date, endDate: Date): "active" | "inactive" | "notStarted" => {
  const today = new Date();

  if (today < startDate) {
    return "notStarted";
  } else if (today > endDate) {
    return "inactive";
  } else {
    return "active";
  }
};

export const getCurrentPeriodIndex = (periods?: Period[], currentDate: Date = new Date()): number => {
  if (!periods?.length) {
    return -1;
  }

  const currentPeriodIndex = periods.findIndex(
    (period) => currentDate >= period.startDate.toDate() && currentDate <= period.endDate.toDate()
  );

  // If no current period found, return the last period
  return currentPeriodIndex >= 0 ? currentPeriodIndex : periods.length - 1;
};

export const calucluateTotalPeriodSpend = (periodSpend?: PeriodSpend, adjustments?: Adjustment[]) => {
  if (!periodSpend) {
    return 0;
  }

  const totalAdjustments = adjustments?.reduce((sum: number, adj: Adjustment) => sum + Number(adj.amount), 0) ?? 0;
  const total = Number(periodSpend.total) || 0;
  const shortfallRollover = Number(periodSpend.shortfallRollover) || 0;
  const rollover = Number(periodSpend.rolloverFromPrevPeriod) || 0;

  return total + shortfallRollover + rollover + totalAdjustments;
};

export const calculateRemaining = (periodSpend?: PeriodSpend, period?: Period, adjustments?: Adjustment[]) => {
  if (!periodSpend || !period) {
    return period?.commitmentValue ?? 0;
  }

  return period.commitmentValue - calucluateTotalPeriodSpend(periodSpend, adjustments);
};
